import { Controller } from "@hotwired/stimulus";
import React from "react";
import ReactDOM from "react-dom/client";
import '@dexhunterio/swaps/lib/assets/style.css'
import Swap from '@dexhunterio/swaps'

export default class extends Controller {
  static values = { partnerCode: String }
  static targets = ["swapContainer"]

  show(event) {
    this.tokenId = event.currentTarget.dataset.tokenId

    if(this.hasSwapContainerTarget && this.tokenId) {
      if(!this.rootElement) {
        this.rootElement = ReactDOM.createRoot(this.swapContainerTarget);
      }

      this.rootElement.render(<Swap {...this.settings()} />);
    }
  }

  settings() {
    return {
      orderTypes: ["SWAP", "LIMIT"],
      defaultToken: this.tokenId,
      colors: {
        background: "#FFFFFF",
        containers: "#f8f9fd",
        subText: "#859DA2",
        mainText: "#11424A",
        accent: "#00D061"
      },
      width: "100%",
      theme: "light",
      partnerCode: this.partnerCodeValue,
      partnerName: "CardanoCube.io"
    }
  }
}